<template>
  <div>
    <v-container fluid>
      <v-card
        height="400"
        color="transparent"
        flat
      >
        <v-card-title class="text-h2">
          Erreur 408
        </v-card-title>
        <v-card-subtitle class="text-h6">
          Echec de la connection au serveur.
        </v-card-subtitle>
        <v-card-text>
          Vérifiez que vous êtes toujours connecté à Internet, ou contactez les
          membres de DaTA pour obtenir de l'aide si vous pensez qu'il y a un
          problème.
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="auto">
              <v-btn
                outlined
                class="mt-1"
                @click="goback"
              >
                Réessayer
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                outlined
                to="/"
                class="mt-1"
              >
                Retour Accueil
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Error408",
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
